import { useEffect, useState } from "react";
import { Layout } from "antd";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";

import useWindowSize from "../../utils/useWindowSize";
import {
  resetUserState,
  viewerStatus,
  emailConfirmation,
} from "../../store/userInfo";
import { setSelectedNotification } from "../../store/notification";
import { resetAuthState } from "../../store/auth";
import { resetCompanyState } from "../../store/userCompany";
import { resetIdentityState } from "../../store/userIdentity";
import { resetIDINState } from "../../store/iDIN";
import routes from "../../constants/routes";
import { swrFetcher } from "../../utils/swrFetcher";
import createQuery from "../../utils/createQuery";

const { Header } = Layout;

const RegistrationModal = dynamic(() => import("../modals/RegistrationModal"), {
  ssr: false,
});

const MobileDrawer = dynamic(() => import("../navigation/MobileDrawer"), {
  ssr: false,
});

const MobileMenu = dynamic(() => import("../navigation/MobileMenu"), {
  ssr: false,
});

const WebMenu = dynamic(() => import("../navigation/WebMenu"), { ssr: false });

function NavBar({ onboarding, maxWidth, defaultOpenKeys }) {
  const { width } = useWindowSize();
  const router = useRouter();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userInfo);

  const userIdentity = useSelector((state) => state.userIdentity);

  const { token } = useSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);
  const [isRegistrationModalVisible, setIsRegistrationModalVisible] =
    useState(false);
  const [value, setValue] = useState(null);

  const mobile = width < 576;
  const wrapperStyle = maxWidth ? { maxWidth: 1300 } : { maxWidth: "none" };
  const outerWrapperStyle = mobile
    ? { justifyContent: "flex-start" }
    : { justifyContent: "center" };

  const onSearch = (searchText) => {
    setValue(searchText);
  };

  const handleSearch = async () => {
    router.push(`/insolvencies?query=${value}`);
  };

  const closeRegistrationModal = async () => {
    if (user.isViewer) dispatch(viewerStatus(false));
    setIsRegistrationModalVisible(false);
    if (router.query.login) await router.replace(router.route);
  };

  const messageURL = `/messages?${createQuery({
    filters: {
      isOpened: false,
      user: user.currentUser?.id,
    },
    sort: "publishedAt:DESC",
    limit: 6,
    populate: "deep",
  })}`;

  const { data: messages, mutate } = useSWR(
    user.currentUser && user.isSignIn && token ? [messageURL, token] : null,
    ([url, token]) => swrFetcher(url, token)
  );

  const handleNotificationSelected = async (notificationId) => {
    dispatch(setSelectedNotification(notificationId));
    router.push(`/${user?.currentUser?.id}/notifications`);
  };

  const handleLogOut = async () => {
    await dispatch(resetAuthState());
    await router.replace("/");
    await dispatch(resetUserState());
    await dispatch(resetCompanyState());
    await dispatch(resetIdentityState());
    await dispatch(resetIDINState());
  };

  useEffect(() => {
    if (router.query.code || router.query.login) {
      setIsRegistrationModalVisible(true);
    }
    if (router.query.confirmation) {
      dispatch(emailConfirmation(router.query.confirmation));
    }
  }, [router.query, dispatch]);

  useEffect(() => {
    if (user.isViewer && !router.query.confirmation) {
      setIsRegistrationModalVisible(true);
    }
  }, [user.isViewer]);


  useEffect(() => {
    if (user.currentUser && !userIdentity?.identity && !user.currentUser.twoFAEnabled) {
      router.replace("/onboarding");
    }
  }, [userIdentity, user.currentUser]);

  useEffect(() => {
    if (user.currentUser && user.emailLinkConfirmed) {
      router.replace("/onboarding");
    }
  }, [user.emailLinkConfirmed, user.currentUser]);

  const navigationProps = {
    onboarding,
    messages,
    onSearch,
    handleLogOut,
    handleNotificationSelected,
    user,
    visible,
    setVisible,
    width,
    routes,
    defaultOpenKeys,
    handleSearch,
  };
  return (
    width && (
      <Header className="header" style={outerWrapperStyle}>
        {mobile ? (
          <MobileMenu {...navigationProps} />
        ) : (
          <div
            className="ant-layout-header header"
            style={{ ...wrapperStyle, margin: "auto" }}
          >
            <WebMenu {...navigationProps} />
          </div>
        )}
        <MobileDrawer {...navigationProps} />
        {isRegistrationModalVisible && (
          <RegistrationModal
            isModalVisible={isRegistrationModalVisible}
            closeModal={closeRegistrationModal}
          />
        )}
      </Header>
    )
  );
}

NavBar.defaultProps = {
  maxWidth: false,
  onboarding: false,
  routes: [],
};

NavBar.propTypes = {
  maxWidth: PropTypes.bool,
  onboarding: PropTypes.bool,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      breadcrumbName: PropTypes.string,
      path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          pathname: PropTypes.string,
          query: PropTypes.objectOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          ),
        }),
      ]),
      exact: PropTypes.bool,
    })
  ),
};

export default NavBar;
