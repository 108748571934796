import qs from "qs";

/**
 * @typedef {Object} QueryOptions
 * @property {Object} filters - Filters to apply to the query.
 * @property {number} limit - Maximum number of results to return.
 * @property {string} sort - Sorting criteria for the results.
 * @property {string} populate - Flag indicating whether to populate related data.
 * @property {number} start - Starting index for the query.
 * @property {Array} include - Related data to include in the results.
 * @property {number} page - Page number for the query.
 */
/**
 * Creates a query with specified options.
 * @param {QueryOptions} options - Options for the query.
 */

function createQuery(params) {
  const stringified = qs.stringify({
    ...params,
  });

  return stringified;
}

export default createQuery;
