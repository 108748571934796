const emptyLot = {
  id: null,
  name: null,
  description: null,
  currentBid: null,
  startingBid: "0",
  subCategory: {
    id: null,
    title: null,
    category: {
      id: null,
      title: null,
    },
  },
  images: [],
  documents: [],
};

export default emptyLot;
