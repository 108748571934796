// Returns sum of array if the array contains only numbers. Else it returns an error.
function arraySum(array) {
  if (array.some((elem) => typeof elem !== "number")) {
    throw new Error("Array may contain only numbers!");
  }

  return array.reduce((a, b) => a + b, 0);
}

export default arraySum;
