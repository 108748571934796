import { Layout } from "antd";
import PropTypes from "prop-types";

function Container({ children, style }) {
  return (
    <Layout className="container" style={style}>
      {children}
    </Layout>
  );
}

Container.defaultProps = {
  style: null,
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

export default Container;
