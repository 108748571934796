const emptyAuctionCategoryPage = {
  id: null,
  endDate: null,
  startDate: null,
  status: null,
  auctionType: null,
  currency: null,
  participants: [],
  requesters: [],
  invited: [],
  author: null,
  ndaTemplate: {},
  biddingSteps: [],
  visibility: null,
  lots: [],
  subCategories: [],
  category: null,
  categoryTitle: null,
  subCategory: null,
  subCategoryTitle: null,
  publishedAt: null,
  insolvency: {
    id: null,
    name: null,
    description: null,
    startingBid: null,
  },
  auctionSteps: [],
};

export default emptyAuctionCategoryPage;
