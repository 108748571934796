import { Button, Layout, notification } from "antd";
import React, { useEffect } from "react";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import PropTypes from "prop-types";

const { Content } = Layout;

const MaxWidthWrapper = ({ children, maxWidth }) => {
  if (maxWidth) {
    return <div style={{ maxWidth: 1300, margin: "auto" }}>{children}</div>;
  }

  return children;
};

MaxWidthWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.bool.isRequired,
};

function ContentContainer({ children, style, maxWidth }) {
  const cookieName = "cookies-accepted";
  const { t, lang } = useTranslation("common");
  const Context = React.createContext({ name: "Default" });
  const [api, contextHolder] = notification.useNotification();
  const key = "cookiePopUpNotificationKey";

  // Open cookie-notification
  useEffect(() => {
    const CloseButton = () => (
      <Button
        type="primary"
        onClick={() => {
          localStorage.setItem(cookieName, "Ok");
          notification.close(key);
        }}
      >
        {t("cookie_notification_close_text")}
      </Button>
    );

    if (!localStorage.getItem(cookieName)) {
      api.open({
        key,
        duration: 0,
        btn: <CloseButton />,
        placement: "bottomRight",
        message: t("cookie_notification_title"),
        description: (
          <>
            {t("cookie_notification_description")}
            <Link href="/privacy-policy">{t("privacy_policy")}</Link>
          </>
        ),
      });
    }
  }, [api, t, lang]);

  return (
    <Context.Provider value={{ name: "Ant Design" }}>
      {contextHolder}
      <Layout>
        <Content className="content" style={style}>
          <MaxWidthWrapper maxWidth={maxWidth}>{children}</MaxWidthWrapper>
        </Content>
      </Layout>
    </Context.Provider>
  );
}

ContentContainer.defaultProps = {
  style: null,
  maxWidth: false,
};

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  maxWidth: PropTypes.bool,
};

export default ContentContainer;
