function convertObject(obj) {
  // Base case: if obj is not an object, return it as is
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  // check if obj has only 2 keys, id and attributes
  if (
    Object.keys(obj).length === 2 &&
    obj?.hasOwnProperty?.("id") &&
    obj?.hasOwnProperty?.("attributes") &&
    typeof obj.id === "number" &&
    typeof obj.attributes === "object"
  ) {
    return convertObject({ ...obj.attributes, id: obj.id });
  }

  // If obj is an object with only a 'data' key and data is also an object, directly assign data to its parent
  if (
    Object.keys(obj).length === 1 &&
    obj?.hasOwnProperty?.("data") &&
    typeof obj.data === "object" &&
    obj.data !== null
  ) {
    return convertObject(obj.data);
  }

  if (Array.isArray(obj)) {
    return obj.map((element) => convertObject(element));
  }

  // Otherwise, iterate over the keys of obj and recursively convert each value
  const convertedObj = {};

  // check if obj is an array
  // if so, iterate over each element and convert it

  for (const key in obj) {
    if (obj?.hasOwnProperty?.(key)) {
      convertedObj[key] = convertObject(obj[key]);
    }
  }

  return convertedObj;
}

export function parseResponse(response) {
  return convertObject(response?.data);
}
