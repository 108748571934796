const commonProps = {
  translation: true,
  exact: true,
};

// Routes array used to map the leftsidebar menu items.
const routes = [
  {
    title: "all_insolvencies",
    breadcrumbName: "All insolvencies",
    path: "/insolvencies",
    role: ["authenticated", "author", "admin"],
    ...commonProps,
  },
  {
    title: "all_users",
    breadcrumbName: "Manage users",
    path: "/users",
    role: ["admin"],
    ...commonProps,
  },
  {
    title: "add_insolvency",
    breadcrumbName: "Add insolvency",
    path: {
      pathname: "/[user]/insolvencies/add",
      query: {},
    },
    role: ["author"],
    ...commonProps,
  },
  {
    title: "my_projects",
    breadcrumbName: "My insolvencies",
    path: {
      pathname: "/[user]/insolvencies",
      query: {},
    },
    role: ["authenticated", "author"],
    ...commonProps,
  },
  {
    title: "my_notifications",
    breadcrumbName: "My Notifications",
    path: {
      pathname: "/[user]/notifications",
      query: {},
    },
    role: ["authenticated", "author"],
    ...commonProps,
  },
  {
    title: "my_bids",
    breadcrumbName: "My bids",
    path: {
      pathname: "/[user]/bids",
      query: {},
    },
    role: ["authenticated", "author"],
    ...commonProps,
  },
  {
    title: "my_ndas",
    breadcrumbName: "My ndas",
    path: {
      pathname: "/[user]/ndas",
      query: {},
    },
    role: ["authenticated", "author"],
    ...commonProps,
  },
];

export default routes;
