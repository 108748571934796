import { Button, Form, Input, Row } from "antd";
import useTranslation from "next-translate/useTranslation";
import PropTypes from "prop-types";

const { Item } = Form;

// Form to subscribe to the weekly digest. Only has email item.
function FooterEmailForm({ onFinish, loading, form }) {
  const { t } = useTranslation("common");

  return (
    <Form
      form={form}
      name="footerEmailForm"
      layout="vertical"
      onFinish={onFinish}
      style={{ width: "100%" }}
    >
      <Item
        name="email"
        style={{ marginBottom: 10 }}
        rules={[
          {
            type: "email",
            message: `${t("email_address")} ${t("is_not_valid")}`,
            validateTrigger: "onFinish",
          },
          {
            required: true,
            message: `${t("email_address")} ${t("is_required")}`,
            validateTrigger: "onFinish",
          },
        ]}
      >
        <Input placeholder={t("email_address")} />
      </Item>
      <Item>
        <Row justify="end">
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("submit")}
          </Button>
        </Row>
      </Item>
    </Form>
  );
}

FooterEmailForm.defaultProps = {
  loading: false,
};

FooterEmailForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.shape({
    resetFields: PropTypes.func,
  }).isRequired,
};

export default FooterEmailForm;
