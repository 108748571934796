import {
  Col,
  Layout,
  Row,
  Space,
  Typography,
  Button,
  message,
  Form,
} from "antd";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import FooterEmailForm from "../forms/FooterEmailForm";

import { signUpNewsletter, viewerStatus } from "../../store/userInfo";

const { Footer } = Layout;
const { Title, Paragraph } = Typography;

function FooterContainer({ maxWidth }) {
  const { t } = useTranslation("common");
  const [newsletterForm] = Form.useForm();
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userInfo);

  const rowStyle = maxWidth ? { maxWidth: 1300 } : { maxWidth: "none" };

  const createNewUserForNewsletter = async (values) => {
    setNewsletterLoading(true);

    const { email } = values;

    try {
      const res = await dispatch(signUpNewsletter(email));

      if (res) {
        message.success(t("newsletter_success"));
        newsletterForm.resetFields();
      } else {
        message.error(t("newsletter_error"));
      }

      setNewsletterLoading(false);
    } catch (error) {
      setNewsletterLoading(false);
      message.error(t("newsletter_error"));
    }
  };

  return (
    <Footer className="footer">
      <div style={{ ...rowStyle, margin: "auto" }}>
        <Row
          gutter={[
            {
              xs: 12,
              sm: 24,
              md: 24,
              lg: 40,
            },
            {
              xs: 30,
              sm: 30,
              md: 30,
              lg: 30,
            },
          ]}
          justify="space-between"
        >
          <Col md={6} sm={12} xs={24}>
            <div style={{ width: 200, height: 40, position: "relative" }}>
              <Image
                src="/images/logo-white.png"
                alt={t("footer_logo_alt_text")}
                layout="fill"
              />
            </div>
            <Paragraph style={{ color: "#f0f0f0", marginTop: 15 }}>
              &#169; Bexchange {new Date().getFullYear()}
            </Paragraph>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <Space direction="vertical">
              <Title level={4} style={{ color: "#fff" }}>
                {t("service_and_contact")}
              </Title>
              <Link href="/support">{t("contact_and_question")}</Link>
              <Button
                type="link"
                onClick={() => !user?.isSignIn && dispatch(viewerStatus(true))}
                style={{ padding: 0, marginBottom: 0, height: "inherit" }}
              >
                {t("register")}
              </Button>
              <Button
                type="link"
                onClick={() => !user?.isSignIn && dispatch(viewerStatus(true))}
                style={{ padding: 0, margin: 0, height: "inherit" }}
              >
                {t("login")}
              </Button>
            </Space>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <Space direction="vertical">
              <Title level={4} style={{ color: "#fff" }}>
                {t("more_information")}
              </Title>
              <Link href="/terms-and-conditions">
                {t("terms_and_conditions")}
              </Link>
              <Link href="/privacy-policy">{t("privacy_policy")}</Link>
              <Link href="/about">{t("about_us")}</Link>
            </Space>
          </Col>
          {!user?.currentUser?.emailNotification && (
            <Col md={6} sm={12} xs={24}>
              <Title level={4} style={{ color: "#fff" }}>
                {t("dont_miss_out")}
              </Title>
              <Paragraph style={{ color: "#f0f0f0" }}>
                {t("subscribe_newsletter")}
              </Paragraph>
              <FooterEmailForm
                onFinish={createNewUserForNewsletter}
                loading={newsletterLoading}
                form={newsletterForm}
              />
            </Col>
          )}
        </Row>
      </div>
    </Footer>
  );
}

FooterContainer.defaultProps = {
  maxWidth: false,
};

FooterContainer.propTypes = {
  maxWidth: PropTypes.bool,
};

export default FooterContainer;
